import { useEffect, useState, useCallback, useRef, forwardRef, useImperativeHandle } from "react";
import dayjs from "dayjs";
// mui component
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

// import user component
import { userState } from "../../../interface/MainInterface";
import { HttpClientApi, CBroadParams, GetChannelinfoParams } from "../../../service/client-rest-api";
import { HttpGameApi } from "../../../service/game-rest-api";
import { HttpStatisticApi } from "../../../service/statistic-rest-api";
import { CommonUtils } from "../../../service/common_utils";
import LoadingCircle from "../../../utilities/LoadingCircle";
import Toast from "../../../utilities/Toast";
import { useInterval } from "../../../utilities/UseInterval";
import { ClientIVSPlayer, player as livePlayer } from "../TheHyundai/ClientIVSPlayer";
import { VodIVSPlayer, player as vodPlayer } from "../TheHyundai/VodIVSPlayer";
import { VodIVSPlayerHigh, player as vodPlayerHigh } from "../TheHyundai/VodIVSPlayerHigh";
import ChatVerVod from "../TheHyundai/ChatVerVod";
import ChatAdmin from "../TheHyundai/ChatAdmin";
import BroadWait from "../TheHyundai/BroadWait";
import LiveInfoHeaderMobile from "./LiveInfoHeaderMobile";
import LiveInfoChatPC from "./LiveInfoChatPC";
import LiveFooterMobile from "./LiveInfoFooterMobile";
import LiveInfoChatInputPC from "./LiveInfoChatInputPC";
import LiveGame from "../TheHyundai/LiveGame";
import LiveGameDraw from "../TheHyundai/LiveGameDraw";
import LiveNoticeQnA from "../TheHyundai/LiveNoticeQnA";
import ChangeVod from "./ChangeVod";
import { Typography } from "@mui/material";
import "./ChannelInfo.css";

interface propsType {
  broadDate: string;
  broadSeq: string;
  windowSize: any;
  userState: userState;
  sendIoTMessageMeta: any;
  sendIoTMessage: any;
}

const clientApi = new HttpClientApi();
const gameApi = new HttpGameApi();
const statisticApi = new HttpStatisticApi();

const ChannelInfoPC = (props: propsType, ref: any) => {
  const [loading, setLoading] = useState(false);
  const cUtils = new CommonUtils();
  const toastRef: any = useRef();
  const liveGameRef: any = useRef();
  const liveNoticeRef: any = useRef();
  const videoMode: number = 1;
  const endLoadInfo: boolean = false;

  const [broadInfo, setBroadInfo] = useState<any>({});
  const [chimeInfo, setChimeInfo] = useState<any>({});

  const [playerID, setPlayerID] = useState("");

  const [streamState, setStreamState] = useState(""); // 스트림 상태
  const [videoStatus, setVideoStatus] = useState("");
  const [muted, setMuted] = useState(true);
  const [braodWait, setBroadWait] = useState(false); // 예약된 방송인지 확인
  const [remainTime, setRemainTime] = useState(""); // 방송 시작까지 남은시간계산
  const [vodType, setVodType] = useState("full");
  const [haveHighlight, setHaveHighlight] = useState(false);
  const [vodStartSecFull, setVodStartSecFull] = useState(0);
  const [vodEndSecFull, setVodEndSecFull] = useState(0);
  const [vodStartSecHigh, setVodStartSecHigh] = useState(-1);
  const [vodEndSecHigh, setVodEndSecHigh] = useState(-1);
  const [vodUrl, setVodUrl] = useState("");
  const [hiddenMenu, setHiddenMenu] = useState(false);

  const [videoPosition, setVideoPosition] = useState(""); // 비디오 재생위치
  const [controlOpen, setControlOpen] = useState(true);

  const [likeCount, setLikeCount] = useState(0);
  const [totalViewCount, setTotalViewCount] = useState(0);
  const [concurrentView, setConcurrentView] = useState(0);

  const [chatOpen, setChatOpen] = useState(false);
  const [chatMsg, setChatMsg] = useState<any>([]);

  const [chatUrl, setChatUrl] = useState("");
  // Q&A
  const [newQnAMsg, setNewQnAMsg] = useState<any>(null);
  const prodList: any = [
    {
      productId: "40A0044509",
      name: "셈플 상품입니다.",
      salePrice: 5000,
      basePrice: 5000,
      stock: 10,
      soldout: false,
      thumbImageUrl: "https://hdimagedev.thehyundai.com/static/0/5/4/04/A0/40A0044509_0_300.jpg",
      detailUrl: "/front/pda/itemPtc.thd?slitmCd=40A0044509",
      cartUrl: "",
    },
    {
      productId: "40A0063602",
      name: "실제상품 확인은 더현대앱으로",
      salePrice: 45000,
      basePrice: 45000,
      stock: 10,
      soldout: false,
      thumbImageUrl: "https://hdimagedev.thehyundai.com/static/0/6/3/06/A0/40A0063602_0_300.jpg",
      detailUrl: "/front/pda/itemPtc.thd?slitmCd=40A0063602",
      cartUrl: "",
    },
  ];

  const [playerSize, setPlayerSize] = useState({ width: 300, height: 600 });
  const [chatSize, setChatSize] = useState({ width: 900, height: 600 });

  // Game Draw
  const [gameInfo, setGameInfo] = useState<any>({});
  const [gameDraw, setGameDraw] = useState(false);
  const [gameWinner, setGameWinner] = useState<any>([]);
  const [gameWinnerDetail, setGameWinnerDetail] = useState<any>([]);
  const [gameSk, setGameSk] = useState("");

  const liveHeaderRef: any = useRef();
  const liveChatRef: any = useRef();
  const liveChatPcRef: any = useRef();
  const liveFooterRef: any = useRef();
  const liveChatTFRef: any = useRef();
  const [chatFontColor, setChatFontColor] = useState("rgb(255, 255, 255)");

  const videoStatusCallback = (status: string) => {
    setVideoStatus(status);
    if (status === "onStatePlaying") ivsInitFinished();
  };

  // VOD의 경우 비디오의 재생위치 콜백
  const videoPositionCallback = (event: string, pos: number) => {
    const videoPos = dayjs(broadInfo.vod_started_at).add(pos, "s").format("YYYYMMDDHHmmss"); // Sec to datetime
    setVideoPosition(videoPos);
  };

  // IVS 초기화 완료
  const ivsInitFinished = () => {
    putMetaFunc("view"); // 시청자 View + 1
    liveHeaderRef.current.initStartIvs();
  };

  // 방송이 송출전이면 방송 남은시간 계산
  const delay: number = 1000; // 1초마다 변경
  useInterval(
    () => {
      const remainSec = cUtils.calRemainSec(broadInfo.broad_start_tm);
      if (remainSec > 0) setRemainTime(cUtils.convertRemainText(remainSec));
      else {
        getChannelInfo();
      }
    },
    braodWait ? delay : null
  );

  // 방송 시작시간 아후 방송 상태가 생성 또는 준비중인지 체크
  const [checkOnAir, setCheckOnAir] = useState(false);
  const [onAirError, setOnAirError] = useState(false);
  const delay2: number = 1000; // 1초마다 변경
  useInterval(
    () => {
      const remainSec = cUtils.calRemainSec(broadInfo.broad_start_tm);
      if (remainSec < 0) {
        if (broadInfo.broad_status === "CREATE" || broadInfo.broad_status === "READY") {
          setOnAirError(true);
        } else {
          setOnAirError(false);
        }
      }
    },
    checkOnAir ? delay2 : null
  );

  useEffect(() => {
    if (broadInfo.broad_status === "CREATE" || broadInfo.broad_status === "READY") {
      setCheckOnAir(true);
    } else {
      setCheckOnAir(false);
      setOnAirError(false);
    }
  }, [broadInfo.broad_status]);

  useEffect(() => {
    if (!cUtils.isEmptyObj(broadInfo)) {
      setVodStartSecFull(broadInfo.vod_start_sec);
      setVodEndSecFull(broadInfo.vod_end_sec);
    }

    // 방송시간 체크하기
    const now = dayjs();
    const startTime = dayjs(broadInfo.broad_start_tm, "YYYYMMDDHHmmss");
    const dateDiff = startTime.diff(now, "s");
    if (dateDiff > 0 && broadInfo.broad_status !== "VOD" && broadInfo.broad_status !== "START") setBroadWait(true);
  }, [broadInfo]);

  // [더현대닷컴 전용] 부모창에 메세지 보내기
  const postMsg = (payload: any, target: any) => {};

  // VOD 하이라이트, 전체보기
  const toggleVodType = () => {
    if (vodType === "highlight") setVodType((vodType) => "full");
    else setVodType((vodType) => "highlight");
  };

  // 볼륨 컨트롤
  const toggleMute = () => {
    let player = livePlayer;
    if (broadInfo.broad_status === "VOD" && vodType === "full") player = vodPlayer;
    if (broadInfo.broad_status === "VOD" && vodType === "highlight") player = vodPlayerHigh;

    const muteNext = !player.isMuted();
    player.setMuted(muteNext);
    setMuted(muteNext);
  };

  // 해상도선택
  const handleResolution = () => {};

  // 채팅방 입장
  const enterChatChannel = async (user_id: string, nick_name: string, isAuth: boolean) => {
    if (props.broadSeq !== undefined) {
      const param: CBroadParams = {
        broad_seq: props.broadSeq,
      };
      const enter_res = await clientApi.enter_chat_channel_frame(param, isAuth, user_id, nick_name);
      if (enter_res.result_code === "200") {
        setChatUrl(enter_res.result_body.presigned_url);
      }
    } else {
      console.error("The channel is undefined");
    }
  };

  // Chime 초기화 완료
  const chatInitCallback = (result: boolean) => {};

  // 이미 화면 로딩이 끝났는데 영상이 아직 출력중이지 않으면 영상이 시잘될때 스트림의 상태에 따라서 비디오를 오토플레이한다.
  const streamEventCallback = (event_name: string) => {
    setStreamState(event_name);
  };

  // APP Floating controls
  const handleNoFloating = useCallback(() => {}, []);

  const handleYesFloating = useCallback(() => {}, []);

  // 방송 입장 시 진행 중인 게임 리스트 조회
  const loadChannelGameList = async () => {
    if (props.broadSeq !== undefined) {
      const param: any = {
        broad_seq: props.broadSeq,
      };
      const res = await gameApi.get_live_game_info(param);
      if (!cUtils.isEmptyObj(res.response.game_info)) {
        setGameInfo(res.response.game_info);
        setGameSk((gameSk) => res.response.game_info.sk);
      }
    } else {
      console.error("loadChannelGameList - The channel is undefined");
    }
  };

  // 방송 도중 진행한 게임 목록 조회
  const loadChannelGameInfo = async (sk: string) => {
    if (props.broadSeq !== undefined && sk !== "") {
      const param: any = {
        broad_seq: props.broadSeq,
        sk: sk,
      };

      const res = await gameApi.get_game_info(param);
      if (!cUtils.isEmptyObj(res.response.game_info)) {
        setGameInfo(res.response.game_info);
        setGameSk((gameSk) => res.response.game_info.sk);
      }
    } else {
      console.error("loadChannelGameInfo - The channel is undefined");
    }
  };

  const loadChannelGameDrawInfo = async (sk: string) => {
    if (props.broadSeq !== undefined && sk !== "") {
      const param: any = {
        broad_seq: props.broadSeq,
        sk: sk,
      };

      const res = await gameApi.get_draw_result(param);
      if (res.code === "200") {
        const userIdList = [];
        const userList = [];
        for (const draw_result of res.response.result) {
          let user_id = draw_result.user_id;
          userIdList.push(user_id);
          userList.push({ user_id: user_id, last_no: draw_result.last_no });
        }
        setGameWinner(userIdList);
        setGameWinnerDetail(userList);
        setGameDraw(true);
      } else {
        console.error("get_draw_result failed");
      }
    } else {
      console.error("The channel is undefined");
    }
  };

  const drawEventCallback = () => {
    setGameSk((gameSk) => "");
    setGameDraw(false);
    setGameInfo({});
  };

  // 로그인 페이지 이동
  const goLoginPage = () => {};

  const btnApplyClickCallback = () => {
    putMetaFunc("apply");
  };

  // 우하단 레이어에서 좋아요 버튼 클릭시 부모창 put meta 함수 호출
  const btnLikeClickCallback = () => {
    putMetaFunc("like");
  };

  // 좋아요, 누적시청자 등 시스템 메시지 처리용
  async function putMetaFunc(metaName: string) {
    try {
      props.sendIoTMessageMeta(metaName);
      // Statistic Table incr count
      const param: any = {
        broad_seq: broadInfo.broad_seq,
        prot: metaName,
      };
      await statisticApi.update_count(param);
    } catch (e) {
      console.error("[ERROR] putMetaFunc : ", e);
    }
  }

  // IoT message recieve callback
  function recieveIoTMessage(topic: any, payLoad: any, clientId: string) {
    if (topic === `live/${broadInfo.broad_seq}/payload`) {
      if (payLoad.topic === "chat/message") {
        // 일반 메세지 도착
        liveChatPcRef.current.processChannelMessage(payLoad);
      } else if (payLoad.topic === "chat/block") {
        liveChatRef.current?.addBlockList(payLoad);
      } else if (payLoad.topic === "stream/viewerCount") {
        // 라이브 스트림 동시 시청자 건수
        if (payLoad.viewerCount > concurrentView) {
          setConcurrentView(payLoad.viewerCount);
        }
      } else if (payLoad.topic === "game") {
        // 게임 메세지
        if (payLoad.prot === "start") {
          loadChannelGameInfo(payLoad.game_seq);
        } else if (payLoad.prot === "draw") {
          loadChannelGameDrawInfo(payLoad.game_seq);
        }
      } else if (payLoad.topic === "stream/stream_state") {
        // 라이브 스트림 상태변경
        streamEventCallback(payLoad.event_name);
      } else if (payLoad.topic === "live/metadata") {
        if (payLoad.message === "view" && payLoad.senderId !== clientId) viewCountCallback();
        if (payLoad.message === "like") likeCountCallback();
        if (payLoad.message === "like" && payLoad.senderId !== clientId) newLikeUpCallback();
        if (payLoad.message === "start_stream") loadChannelInfoOnly();
        if (payLoad.message === "ready_stream") loadChannelInfoOnly();
        if (payLoad.message === "stop_stream") loadChannelInfoOnly();
        if (payLoad.message === "reloadChannelInfo") loadChannelInfoOnly();
        if (payLoad.message === "qna") setQnAArrive(payLoad);
        if (payLoad.message === "chatFontColor") setChatFontColor(payLoad.color);
        if (payLoad.message === "apply") applyCallback();
      }
    }
  }

  // 게임 응모 클릭 시 -> 응모 count 증가
  const applyCallback = () => {
    liveGameRef.current.applyNumCallback();
  };

  // 좋아요 버튼 클릭시 상단 레이어 전달 -> 좋아요 count 증가
  const likeCountCallback = () => {
    liveHeaderRef.current.addLikeCount();
  };

  // 다른 사람이 좋아요 누른것을 우하단 레이어에 전달 -> 하트 이벤트 발생
  const newLikeUpCallback = () => {
    liveFooterRef.current.addNewLikeCount();
  };

  const viewCountCallback = () => {
    liveHeaderRef.current.addViewCount();
  };

  // QnA 알림
  const setQnAArrive = (payload: any) => {
    const tmpQnA = {
      qContent: payload.q,
      qUserName: payload.qSender,
      aContent: payload.a,
    };
    setNewQnAMsg(tmpQnA);
  };

  // 채팅 메세지 전송
  const sendMessage = async (msg: string, meta: string) => {
    liveChatPcRef.current.sendMessage(msg, meta);
  };

  // 채팅버튼 클릭시
  const fncBtnChatClick = async (status: boolean) => {
    if (props.userState.id !== "") {
      if (broadInfo.broad_status !== "VOD") liveChatTFRef.current.handleHiddenForm(false);
      setChatOpen((chatOpen) => status);
      setTimeout(() => {
        liveChatPcRef.current.scrollToBottom();
      }, 200);
    }
  };

  // 화면 클릭시 메뉴 숨기기
  const fncBodyClick = (e: any) => {
    const width = window.innerWidth;
    if (width < 200) return;
    if (
      e.target === document.getElementById("tab-screen-content") ||
      e.target === document.getElementsByClassName("thd-chat-list")[0] ||
      e.target === document.getElementsByClassName("live-content-chat")[0] ||
      e.target === document.getElementsByClassName("live-coupon-root")[0] ||
      e.target === document.getElementById("divheartActions")
    ) {
      setHiddenMenu((hiddenMenu) => !hiddenMenu);
      setChatOpen(false);
      setControlOpen(true);
      if (broadInfo.broad_status !== "VOD") liveChatTFRef.current.handleHiddenForm(true);
    }
  };

  const fncResetQuote = () => {
    liveChatPcRef.current.fncResetQuote();
  };

  // 채널정보 조회
  const getChannelInfo = useCallback(async () => {
    const param: GetChannelinfoParams = {
      pk: `broad_date#${props.broadDate}`,
      broad_seq: props.broadSeq,
      st_add: "1",
    };

    const res = await clientApi.get_channel_info(param);
    if (res.result_code === "200") {
      res.result_body.pk = `broad_date#${props.broadDate}`;
      if (res.result_body.broad_status === "VOD") setVodUrl(res.result_body.vod_url);
      if (
        // VOD 상태이며 하이라이트가 있는 경우
        res.result_body.most_concurrent_view_timestamp !== "" &&
        res.result_body.vod_started_at !== "" &&
        res.result_body.most_concurrent_view_timestamp > res.result_body.vod_started_at &&
        res.result_body.broad_status === "VOD"
      ) {
        if (res.result_body.high_start_sec > 0) {
          setVodStartSecHigh(res.result_body.high_start_sec);
          setVodEndSecHigh(res.result_body.high_start_sec + 150);
        } else {
          const date1 = dayjs(res.result_body.most_concurrent_view_timestamp, "YYYYMMDDHHmmss");
          const date2 = dayjs(res.result_body.vod_started_at, "YYYYMMDDHHmmss");
          const diff = date1.diff(date2, "s");
          const enddate = date1.add(150, "s").format("YYYYMMDDHHmmss");
          const endSec = dayjs(enddate, "YYYYMMDDHHmmss").diff(date2, "s");
          if (diff > 0) {
            res.result_body.high_start_sec = diff;
            setVodStartSecHigh(diff);
            setVodEndSecHigh(endSec);
          }
        }

        setVodType("highlight");
        setHaveHighlight(true);
      }
      setBroadInfo(res.result_body);
      const chimeinfo = {
        chime_admin_arn: res.result_body.chime_admin_arn,
        chime_channel_arn: res.result_body.chime_channel_arn,
      };
      setChimeInfo(chimeinfo);
    } else {
      console.error("에러 발생 : " + res.result_body);
    }
  }, [props.broadDate, props.broadSeq]);

  const loadChannelInfoOnly = async () => {
    if (props.broadSeq !== undefined) {
      const param: CBroadParams = {
        broad_seq: props.broadSeq,
      };

      const res = await clientApi.get_channel_info(param);
      res.result_body.pk = `broad_date#${props.broadDate}`;
      setBroadInfo(res.result_body);
    } else {
      console.error("The channel is undefined");
    }
  };

  // 채팅 입력창 포커스
  const chatInpuFocus = () => {
    liveChatTFRef.current.chatInputFocus();
  };

  const setVodTimeStamp = (vodType: string, url: string, vodStartSec: number, vodEndSec: number) => {
    setVodUrl("");
    setTimeout(() => {
      setVodType(vodType);
      if (vodType === "full") {
        setVodStartSecFull(vodStartSec);
        setVodEndSecFull(vodEndSec);
      } else {
        setVodStartSecHigh(vodStartSec);
        setVodEndSecHigh(vodStartSec + 150);
      }
      setVodUrl(url);
    }, 1000);
  };

  const loadStatisticInfo = async () => {
    if (props.broadSeq !== undefined) {
      const param: any = {
        broad_seq: props.broadSeq,
      };
      const res = await statisticApi.get_statistic_info(param);
      if (res.code === "200") {
        if (!cUtils.isEmptyObj(res.response.statistic_info)) {
          setLikeCount(res.response.statistic_info.total_likes);
          setTotalViewCount(res.response.statistic_info.total_views);
        }
      }
    } else {
      console.error("loadStatisticInfo - The channel is undefined");
    }
  };

  useEffect(() => {
    getChannelInfo();
    loadStatisticInfo();
  }, [getChannelInfo]);

  useEffect(() => {
    setPlayerSize({
      width: (props.windowSize.height - 110) * (9 / 16),
      height: props.windowSize.height - 110,
    });
    setChatSize({
      width: props.windowSize.width - (props.windowSize.height - 110) * (9 / 16) - 60,
      height: props.windowSize.height - 110,
    });
  }, [props.windowSize]);

  useImperativeHandle(ref, () => ({
    recieveIoTMessage,
  }));

  useEffect(() => {
    setPlayerID(props.userState.id);
    enterChatChannel(props.userState.id, props.userState.nickName, true);
    loadChannelGameList();
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setLoading(false);
      setBroadInfo({});
      setChimeInfo({});
      setPlayerID("");
      setStreamState("");
      setVideoStatus("");
      setMuted(true);
      setBroadWait(false);
      setRemainTime("");
      setVodType("full");
      setHaveHighlight(false);
      setVodStartSecFull(0);
      setVodEndSecFull(0);
      setVodStartSecHigh(-1);
      setVodEndSecHigh(-1);
      setVodUrl("");
      setHiddenMenu(false);
      setVideoPosition("");
      setControlOpen(true);
      setLikeCount(0);
      setTotalViewCount(0);
      setConcurrentView(0);
      setChatOpen(false);
      setChatUrl("");
      setPlayerSize({ width: 300, height: 600 });
      setChatSize({ width: 900, height: 600 });
      setChatFontColor("rgb(255, 255, 255)");
      setGameInfo({});
      setGameDraw(false);
      setGameWinner([]);
      setGameWinnerDetail([]);
      setGameSk("");
      setCheckOnAir(false);
      setOnAirError(false);
    };
  }, []);

  return (
    <>
      {!cUtils.isEmptyObj(broadInfo) && (
        <Box>
          <Grid className="" container spacing={1} sx={{ paddingX: 0, justifyContent: "space-between" }} component="div">
            {/* 라이브 화면 */}
            <Box sx={{ position: "relative", height: playerSize.height, width: playerSize.width }}>
              <div className="Live-Layout-inner">
                <div className="Live-Swipe-content">
                  <div className="VideoPlayer-wrapper-admin color-snow">
                    {braodWait && broadInfo.broad_seq !== "" ? (
                      <BroadWait remainTime={remainTime} broad_info={broadInfo} />
                    ) : (
                      props.userState.id !== "" &&
                      broadInfo.broad_status !== "VOD" &&
                      broadInfo.broad_seq !== "" && (
                        <ClientIVSPlayer
                          endLoadInfo={endLoadInfo}
                          broad_seq={broadInfo.broad_seq}
                          broad_status={broadInfo.broad_status}
                          playbackUrl={broadInfo.ivs_playback_url}
                          streamState={streamState}
                          videoStatusCallback={videoStatusCallback}
                          muted={muted}
                          webViewStatus={"web"}
                          rehearsal={false}
                        />
                      )
                    )}
                    {broadInfo.broad_status === "VOD" &&
                    broadInfo.broad_seq !== "" &&
                    broadInfo.broad_seq !== undefined &&
                    vodUrl !== "" ? (
                      vodType === "highlight" ? (
                        <VodIVSPlayerHigh
                          endLoadInfo={endLoadInfo}
                          broad_seq={broadInfo.broad_seq}
                          playbackUrl={vodUrl}
                          vod_start_sec={vodStartSecHigh}
                          vod_end_sec={vodEndSecHigh}
                          videoStatusCallback={videoStatusCallback}
                          videoPositionCallback={videoPositionCallback}
                          hidden_menu={hiddenMenu}
                          muted={muted}
                          controlOpen={controlOpen}
                        />
                      ) : (
                        <VodIVSPlayer
                          endLoadInfo={endLoadInfo}
                          broad_seq={broadInfo.broad_seq}
                          playbackUrl={vodUrl}
                          vod_start_sec={vodStartSecFull}
                          vod_end_sec={vodEndSecFull}
                          videoStatusCallback={videoStatusCallback}
                          videoPositionCallback={videoPositionCallback}
                          hidden_menu={hiddenMenu}
                          muted={muted}
                          controlOpen={controlOpen}
                        />
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                  <div
                    className={
                      hiddenMenu && broadInfo.broad_status === "VOD"
                        ? "Tap-screen-wrapper color-snow bottom-50"
                        : "Tap-screen-wrapper color-snow"
                    }
                  >
                    <div id="tab-screen-content" className="Tap-screen-content" onClick={fncBodyClick}>
                      <div className="live-header-root">
                        {broadInfo.broad_seq !== "" ? (
                          <LiveInfoHeaderMobile
                            ref={liveHeaderRef}
                            userState={props.userState}
                            broadInfo={broadInfo}
                            broad_seq={broadInfo.broad_seq}
                            broad_title={broadInfo.broad_title}
                            broad_status={broadInfo.broad_status}
                            video_status={videoStatus}
                            host_picture={broadInfo.host_picture}
                            host_name={broadInfo.host_name}
                            postMsg={postMsg}
                            tot_view_count={totalViewCount}
                            tot_like_count={likeCount}
                            tot_concurrent_view={concurrentView}
                            hidden_menu={hiddenMenu}
                            vodType={vodType}
                            haveHighlight={haveHighlight}
                            toggleVodType={toggleVodType}
                            muted={muted}
                            toggleMute={toggleMute}
                            braodWait={braodWait}
                            handleResolution={handleResolution}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="live-content-root">
                        <div className={"live-content-wrapper chat-bg-transparent"}>
                          <div className="live-content-chat live-content-chat-mobile">
                            {playerID !== "" && broadInfo.broad_status !== "VOD" && broadInfo.broad_status !== "STOP" && (
                              <ChatAdmin
                                adminArn={chimeInfo.chime_admin_arn}
                                chat_open={chatOpen}
                                hidden_menu={hiddenMenu}
                                userState={props.userState}
                                chatFontColor={chatFontColor}
                                chatMsg={chatMsg}
                              />
                            )}

                            {playerID !== "" && chatUrl !== "" && broadInfo.broad_status === "VOD" && (
                              <ChatVerVod
                                ref={liveChatRef}
                                broad_seq={broadInfo.broad_seq}
                                channelArn={chimeInfo.chime_channel_arn}
                                adminArn={chimeInfo.chime_admin_arn}
                                chatUrl={chatUrl}
                                chat_open={chatOpen}
                                screenMode={"H"}
                                hidden_menu={hiddenMenu}
                                chatInitCallback={chatInitCallback}
                                player_id={playerID}
                                streamEventCallback={streamEventCallback}
                                videoPosition={videoPosition}
                                vod_started_at={broadInfo.vod_started_at}
                                userState={props.userState}
                                setting={broadInfo.setting}
                                isNotFloat={handleNoFloating}
                                isFloat={handleYesFloating}
                              />
                            )}
                          </div>
                          {gameSk !== "" && broadInfo.broad_status !== "STOP" ? (
                            <div className={hiddenMenu ? "display-none" : "live-content-game"}>
                              <LiveGame
                                ref={liveGameRef}
                                gameInfo={gameInfo}
                                userId={props.userState.id}
                                custNo={props.userState.id}
                                lastNo="admin"
                                applyClick={btnApplyClickCallback}
                                goLoginPage={goLoginPage}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                          {broadInfo.broad_seq !== "" && broadInfo.broad_status !== "STOP" ? (
                            <div
                              className={
                                hiddenMenu
                                  ? "display-none"
                                  : broadInfo.broad_status === "VOD" && prodList.length === 0
                                  ? "live-content-notice mb-17"
                                  : "live-content-notice"
                              }
                            >
                              <LiveNoticeQnA
                                isNotFloat={handleNoFloating}
                                isFloat={handleYesFloating}
                                newQnAMsg={newQnAMsg}
                                ref={liveNoticeRef}
                                broad_info={broadInfo}
                                videoMode={videoMode}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                          {gameDraw && !braodWait && broadInfo.broad_status !== "STOP" ? (
                            <div className={hiddenMenu ? "display-none" : "live-game-draw-root"}>
                              <LiveGameDraw
                                gameWinner={gameWinner}
                                gameWinnerDetail={gameWinnerDetail}
                                drawEventCallback={drawEventCallback}
                                userId={props.userState.id}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                          <div className={"live-content-footer live-content-footer-pc"}>
                            {broadInfo.broad_seq !== "" ? (
                              <LiveFooterMobile
                                ref={liveFooterRef}
                                isNotFloat={handleNoFloating}
                                isFloat={handleYesFloating}
                                broad_seq={broadInfo.broad_seq}
                                broad_status={broadInfo.broad_status}
                                user_id={props.userState.id}
                                userState={props.userState}
                                like_click={btnLikeClickCallback}
                                hidden_menu={hiddenMenu}
                                sendMessage={sendMessage}
                                prodList={prodList}
                                postMsg={postMsg}
                                newQnAMsg={newQnAMsg}
                                openChatInput={fncBtnChatClick}
                                videoMode={videoMode}
                                broadInfo={broadInfo}
                                chimeInfo={chimeInfo}
                                broadDate={props.broadDate}
                                sendIoTMessage={props.sendIoTMessage}
                                sendIoTMessageMeta={props.sendIoTMessageMeta}
                                getChannelInfo={getChannelInfo}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
            {/* 라이브 화면 */}
            {/* 채팅 화면 */}
            {props.userState.id !== "" && chatUrl !== "" && broadInfo.broad_status !== "VOD" && chimeInfo !== undefined && (
              <Box
                className="live-channel-info-chat-box"
                sx={{
                  position: "relative",
                  height: chatSize.height,
                  width: chatSize.width,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className={"live-content-chat live-channel-info-pc-chat"}>
                  <LiveInfoChatPC
                    ref={liveChatPcRef}
                    broad_seq={broadInfo.broad_seq}
                    channelArn={chimeInfo.chime_channel_arn}
                    adminArn={chimeInfo.chime_admin_arn}
                    userState={props.userState}
                    extra_type={broadInfo.extra_type}
                    sendIoTMessage={props.sendIoTMessage}
                    chatInpuFocus={chatInpuFocus}
                    setChatMsg={setChatMsg}
                  />
                </div>
                <LiveInfoChatInputPC
                  ref={liveChatTFRef}
                  broad_status={broadInfo.broad_status}
                  user_id={props.userState.id}
                  sendMessage={sendMessage}
                  fncResetQuote={fncResetQuote}
                />
              </Box>
            )}
            {/* 채팅 화면 */}
            {broadInfo.broad_status === "VOD" && (
              <Box sx={{ position: "relative", height: chatSize.height, width: chatSize.width, display: "flex" }}>
                <ChangeVod
                  userState={props.userState}
                  broadInfo={broadInfo}
                  getChannelInfo={getChannelInfo}
                  setVodTimeStamp={setVodTimeStamp}
                />
              </Box>
            )}
          </Grid>
        </Box>
      )}
      <Box
        sx={{
          position: "absolute",
          top: "80px",
          left: "520px",
          ...(onAirError ? { display: "" } : { display: "none" }),
        }}
      >
        <Typography sx={{ color: "red", fontWeight: "bold", fontSize: "3rem" }}>!방송 상태를 확인해주세요.</Typography>
      </Box>
      <LoadingCircle loading={loading} />
      <Toast ref={toastRef} />
    </>
  );
};

export default forwardRef(ChannelInfoPC);
