import { useState, useEffect } from "react";

// mui component
import Box from "@mui/material/Box";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";

// import user component
import { appConfig } from "../../../config/Config";

interface propsType {
  hidden_menu: boolean;
  sendIoTMessage: any;
}

const ChatColorChange = (props: propsType) => {
  const [color, setColor] = useState(appConfig.chatFontColorLight);

  const changeColor = () => {
    if (color === appConfig.chatFontcolorDark) {
      setColor(appConfig.chatFontColorLight);
      const payload = { topic: "live/metadata", message: "chatFontColor", color: appConfig.chatFontColorLight };
      props.sendIoTMessage(payload);
    } else {
      setColor(appConfig.chatFontcolorDark);
      const payload = { topic: "live/metadata", message: "chatFontColor", color: appConfig.chatFontcolorDark };
      props.sendIoTMessage(payload);
    }
  };

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setColor(appConfig.chatFontColorLight);
    };
  }, []);

  return (
    <>
      <Box
        sx={props.hidden_menu ? { display: "none" } : { display: "block" }}
        onClick={() => {
          changeColor();
        }}
      >
        <Box
          className="thd-footer-right-icon"
          sx={{
            marginBottom: "8px",
            borderRadius: "25px",
            textAlign: "center",
            fontSize: "2rem",
            ...(color === appConfig.chatFontcolorDark ? { backgroundColor: "#ffffff94" } : { backgroundColor: "#00000094" }),
          }}
        >
          <FormatColorTextIcon sx={{ color: color }} />
        </Box>
      </Box>
    </>
  );
};

export default ChatColorChange;
