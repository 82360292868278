import React from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import LoginIcon from "@mui/icons-material/Login";
import GetAppIcon from "@mui/icons-material/GetApp";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import { NavLink } from "react-router-dom";

import CustomLink from "../service/custom-link/customLink";
import { userState } from "../interface/MainInterface";
import * as AuthMain from "../service/auth/AuthMain";
import { HttpClientApi } from "../service/client-rest-api";
import { appConfig } from "../config/Config";
import "./Header.css";

interface propsType {
  userState: userState;
}

const Header = (props: propsType) => {
  let navigate = useNavigate();
  const clientApi = new HttpClientApi();
  const [openLeftMenu, setOpenLeftMenu] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [userPic, setUserPic] = React.useState<string>("");
  const [headerDisPlay, setHeaderDisplay] = React.useState("none");

  const handleDrawerOpen = () => setOpenLeftMenu(true);
  const handleDrawerClose = () => setOpenLeftMenu(false);

  const menuOpen = Boolean(anchorEl);
  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    if (props.userState.isAuth) {
      setAnchorEl(event.currentTarget);
    } else {
      goLoginPage();
    }
  };
  const handleUserMenu = () => {
    setAnchorEl(null);
  };

  const goMyAccountPage = () => {
    navigate("/myaccount");
  };

  const goLoginPage = () => {
    navigate("/login");
  };

  const goHome = () => {
    // navigate("/");
    window.location.href = "/";
  };

  const handleDownloadOpen = () => {
    window.open("/broadcastDownload");
  };

  const handleGuideOpen = () => {
    window.open("/broadcastGuide");
  };

  async function signOut() {
    const result = await AuthMain.signOut();
    if (result) window.location.href = "/";
  }

  const getUrl = async () => {
    if (props.userState.picture !== "") {
      const image_url = await clientApi.get_presigned_url("user-photos/" + props.userState.picture);
      // console.info("picture : ", userAttr.picture);
      // console.info("image_url : ", image_url);
      setUserPic(image_url.result_body.url);
    }
  };

  React.useEffect(() => {
    getUrl();
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setOpenLeftMenu(false);
      setAnchorEl(null);
      setUserPic("");
      setHeaderDisplay("none");
    };
  }, []);

  React.useEffect(() => {
    // 화면 경로별 해더 감추기
    const pathName: string[] = window.location.pathname.split("/");
    if (pathName.length > 1) {
      if (pathName[1] === "loginFrame") {
        setHeaderDisplay("none");
      } else {
        setHeaderDisplay("flex");
      }
    } else {
      setHeaderDisplay("flex");
    }
  }, [window.location]);

  return (
    <Box sx={{ flexGrow: 1, position: "sticky", top: "0", zIndex: "700", display: headerDisPlay }}>
      <AppBar id="Header-container" position="static">
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
            <IconButton size="large" aria-label="menu" onClick={handleDrawerOpen}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" align="center" className="Header-logo" onClick={goHome}>
              더현대닷컴<span style={{ color: "red" }}>LIVE</span>
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
            <IconButton size="large" aria-label="download" sx={{ fontSize: "14px", borderRadius: "0" }} onClick={handleDownloadOpen}>
              <GetAppIcon />
              <span className="download-text">&nbsp;앱 설치</span>
            </IconButton>
            <Box>
              <IconButton
                onClick={handleAvatarClick}
                size="small"
                // sx={{ ml: 2 }}
                aria-controls={menuOpen ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={menuOpen ? "true" : undefined}
              >
                {props.userState.isAuth ? (
                  // <FaceIcon />
                  <Avatar
                    sx={
                      userPic !== ""
                        ? {}
                        : props.userState.isAdmin
                        ? { width: 32, height: 32, backgroundColor: "#ef09e3" }
                        : { width: 32, height: 32, backgroundColor: "#07582e" }
                    }
                    src={userPic}
                  >
                    {props.userState.nickName === "" ? props.userState.id[0] : props.userState.nickName[0]}
                  </Avatar>
                ) : (
                  <LoginIcon />
                )}
              </IconButton>
              <Menu id="basic-menu" anchorEl={anchorEl} open={menuOpen} onClose={handleUserMenu} onClick={handleUserMenu}>
                {props.userState.isAuth && (
                  <div key={1}>
                    <MenuItem onClick={goMyAccountPage}>My account</MenuItem>
                    <MenuItem onClick={signOut}>Logout</MenuItem>
                  </div>
                )}
              </Menu>
            </Box>
          </Stack>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={openLeftMenu} onClose={handleDrawerClose}>
        <Box sx={{ width: 250 }} onClick={handleDrawerClose} onKeyDown={handleDrawerClose}>
          <List>
            <CustomLink to="/" pageName="홈으로" iconType="home" />
            {/* Admin */}
            {props.userState.isAdmin && (
              <>
                <hr />
                <CustomLink to="/mngChannel/none/none/none" pageName="방송진행관리" iconType="live_tv" />
                <CustomLink to="/statisticChannel" pageName="방송별통계" iconType="bar_chart" />
                <NavLink to={"/"} className={({ isActive }) => "nav-link" + (isActive ? " activated" : "")}>
                  <ListItem button key="송출가이드" onClick={handleGuideOpen}>
                    <ListItemIcon>
                      <Icon>podcasts</Icon>
                    </ListItemIcon>
                    <ListItemText primary="송출가이드" />
                  </ListItem>
                </NavLink>
                <NavLink to={"/"} className={({ isActive }) => "nav-link" + (isActive ? " activated" : "")}>
                  <ListItem button key="앱 다운로드" onClick={handleDownloadOpen}>
                    <ListItemIcon>
                      <Icon>get_app</Icon>
                    </ListItemIcon>
                    <ListItemText primary="앱 다운로드" />
                  </ListItem>
                </NavLink>
                <hr />
              </>
            )}
            {/* Client */}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

export default Header;
