import { useEffect, useState, useCallback, useRef, forwardRef, useImperativeHandle } from "react";
import dayjs from "dayjs";
// mui component
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

// import user component
import { userState } from "../../../interface/MainInterface";
import { HttpClientApi, CBroadParams, GetChannelinfoParams } from "../../../service/client-rest-api";
import { HttpStatisticApi } from "../../../service/statistic-rest-api";
import { CommonUtils } from "../../../service/common_utils";
import { useInterval } from "../../../utilities/UseInterval";
import { ClientIVSPlayer, player as livePlayer } from "../TheHyundai/ClientIVSPlayer";
import { VodIVSPlayer, player as vodPlayer } from "../TheHyundai/VodIVSPlayer";
import { VodIVSPlayerHigh, player as vodPlayerHigh } from "../TheHyundai/VodIVSPlayerHigh";
import BroadWait from "../TheHyundai/BroadWait";
import LiveInfoHeaderMobile from "./LiveInfoHeaderMobile";
import LiveInfoChatMobile from "./LiveInfoChatMobile";
import LiveFooterMobile from "./LiveInfoFooterMobile";
import LiveInfoChatInput from "./LiveInfoChatInput";
import { appConfig } from "../../../config/Config";

interface propsType {
  broadDate: string;
  broadSeq: string;
  userState: userState;
  sendIoTMessageMeta: any;
  sendIoTMessage: any;
}

const clientApi = new HttpClientApi();
const statisticApi = new HttpStatisticApi();

const ChannelInfoMobile = (props: propsType, ref: any) => {
  const cUtils = new CommonUtils();
  const endLoadInfo: boolean = false;
  const vodType: string = "full";
  const haveHighlight: boolean = false;
  const vodStartSecHigh: number = -1;
  const vodEndSecHigh: number = -1;
  const prodList: any = [];
  const newQnAMsg: any = null;
  const videoMode: number = 1;

  const [broadInfo, setBroadInfo] = useState<any>({});
  const [chimeInfo, setChimeInfo] = useState<any>({});

  const [streamState, setStreamState] = useState(""); // 스트림 상태
  const [videoStatus, setVideoStatus] = useState("");
  const [muted, setMuted] = useState(true);
  const [braodWait, setBroadWait] = useState(false); // 예약된 방송인지 확인
  const [remainTime, setRemainTime] = useState(""); // 방송 시작까지 남은시간계산

  const [vodStartSecFull, setVodStartSecFull] = useState(0);
  const [vodEndSecFull, setVodEndSecFull] = useState(0);

  const [hiddenMenu, setHiddenMenu] = useState(false);

  const [controlOpen, setControlOpen] = useState(true);

  const [likeCount, setLikeCount] = useState(0);
  const [totalViewCount, setTotalViewCount] = useState(0);
  const [concurrentView, setConcurrentView] = useState(0);

  const [chatOpen, setChatOpen] = useState(false);

  const liveHeaderRef: any = useRef();
  const liveChatRef: any = useRef();
  const liveFooterRef: any = useRef();
  const liveChatTFRef: any = useRef();

  const videoStatusCallback = (status: string) => {
    setVideoStatus(status);
    if (status === "onStatePlaying") ivsInitFinished();
  };

  // VOD의 경우 비디오의 재생위치 콜백
  const videoPositionCallback = (event: string, pos: number) => {};

  // IVS 초기화 완료
  const ivsInitFinished = () => {
    putMetaFunc("view"); // 시청자 View + 1
    liveHeaderRef.current.initStartIvs();
  };

  // 방송이 송출전이면 방송 남은시간 계산
  const delay: number = 1000; // 1초마다 변경
  useInterval(
    () => {
      const remainSec = cUtils.calRemainSec(broadInfo.broad_start_tm);
      if (remainSec > 0) setRemainTime(cUtils.convertRemainText(remainSec));
      else {
        getChannelInfo();
      }
    },
    braodWait ? delay : null
  );

  // 방송 시작시간 아후 방송 상태가 생성 또는 준비중인지 체크
  const [checkOnAir, setCheckOnAir] = useState(false);
  const [onAirError, setOnAirError] = useState(false);
  const delay2: number = 1000; // 1초마다 변경
  useInterval(
    () => {
      const remainSec = cUtils.calRemainSec(broadInfo.broad_start_tm);
      if (remainSec < 0) {
        if (broadInfo.broad_status === "CREATE" || broadInfo.broad_status === "READY") {
          setOnAirError(true);
        } else {
          setOnAirError(false);
        }
      }
    },
    checkOnAir ? delay2 : null
  );

  useEffect(() => {
    if (broadInfo.broad_status === "CREATE" || broadInfo.broad_status === "READY") {
      setCheckOnAir(true);
    } else {
      setCheckOnAir(false);
      setOnAirError(false);
    }
  }, [broadInfo.broad_status]);

  useEffect(() => {
    if (!cUtils.isEmptyObj(broadInfo)) {
      setVodStartSecFull(broadInfo.vod_start_sec);
      setVodEndSecFull(broadInfo.vod_end_sec);
    }

    // 방송시간 체크하기
    const now = dayjs();
    const startTime = dayjs(broadInfo.broad_start_tm, "YYYYMMDDHHmmss");
    const dateDiff = startTime.diff(now, "s");
    if (dateDiff > 0 && broadInfo.broad_status !== "VOD" && broadInfo.broad_status !== "START") setBroadWait(true);
  }, [broadInfo]);

  // [더현대닷컴 전용] 부모창에 메세지 보내기
  const postMsg = (payload: any, target: any) => {};

  // VOD 하이라이트, 전체보기
  const toggleVodType = () => {};

  // 볼륨 컨트롤
  const toggleMute = () => {
    let player = livePlayer;
    if (broadInfo.broad_status === "VOD" && vodType === "full") player = vodPlayer;
    if (broadInfo.broad_status === "VOD" && vodType === "highlight") player = vodPlayerHigh;

    const muteNext = !player.isMuted();
    player.setMuted(muteNext);
    setMuted(muteNext);
  };

  // 해상도선택
  const handleResolution = () => {};

  // 이미 화면 로딩이 끝났는데 영상이 아직 출력중이지 않으면 영상이 시잘될때 스트림의 상태에 따라서 비디오를 오토플레이한다.
  const streamEventCallback = (event_name: string) => {
    setStreamState(event_name);
  };

  // APP Floating controls
  const handleNoFloating = useCallback(() => {}, []);

  const handleYesFloating = useCallback(() => {}, []);

  // 우하단 레이어에서 좋아요 버튼 클릭시 부모창 put meta 함수 호출
  const btnLikeClickCallback = () => {
    putMetaFunc("like");
  };

  // 좋아요, 누적시청자 등 시스템 메시지 처리용
  async function putMetaFunc(metaName: string) {
    try {
      props.sendIoTMessageMeta(metaName);
      // Statistic Table incr count
      const param: any = {
        broad_seq: broadInfo.broad_seq,
        prot: metaName,
      };
      await statisticApi.update_count(param);
    } catch (e) {
      console.error("[ERROR] putMetaFunc : ", e);
    }
  }

  // IoT message recieve callback
  function recieveIoTMessage(topic: any, payLoad: any, clientId: string) {
    if (topic === `live/${broadInfo.broad_seq}/payload`) {
      if (payLoad.topic === "chat/message") {
        // 일반 메세지 도착
        liveChatRef.current?.processChannelMessage(payLoad);
      } else if (payLoad.topic === "chat/block") {
        liveChatRef.current?.addBlockList(payLoad);
      } else if (payLoad.topic === "stream/viewerCount") {
        // 라이브 스트림 동시 시청자 건수
        if (payLoad.viewerCount > concurrentView) {
          setConcurrentView(payLoad.viewerCount);
        }
      } else if (payLoad.topic === "stream/stream_state") {
        // 라이브 스트림 상태변경
        streamEventCallback(payLoad.event_name);
      } else if (payLoad.topic === "live/metadata") {
        if (payLoad.message === "view" && payLoad.senderId !== clientId) viewCountCallback();
        if (payLoad.message === "like") likeCountCallback();
        if (payLoad.message === "like" && payLoad.senderId !== clientId) newLikeUpCallback();
        if (payLoad.message === "start_stream") loadChannelInfoOnly();
        if (payLoad.message === "ready_stream") loadChannelInfoOnly();
        if (payLoad.message === "stop_stream") loadChannelInfoOnly();
        if (payLoad.message === "reloadChannelInfo") loadChannelInfoOnly();
      }
    }
  }

  // 좋아요 버튼 클릭시 상단 레이어 전달 -> 좋아요 count 증가
  const likeCountCallback = () => {
    // setLikeCount((likeCount: number) => likeCount + 1); // state가 변하면 채팅창 렌더링해서 메모리 낭비 심함.
    liveHeaderRef.current.addLikeCount();
  };

  // 다른 사람이 좋아요 누른것을 우하단 레이어에 전달 -> 하트 이벤트 발생
  const newLikeUpCallback = () => {
    // setNewLikeCount((newLikeCount: number) => newLikeCount + 1); // state가 변하면 채팅창 렌더링해서 메모리 낭비 심함.
    liveFooterRef.current.addNewLikeCount();
  };

  const viewCountCallback = () => {
    // setTotalViewCount((totalViewCount: number) => totalViewCount + 1);// state가 변하면 채팅창 렌더링해서 메모리 낭비 심함.
    liveHeaderRef.current.addViewCount();
  };

  // 채팅 메세지 전송
  const sendMessage = (msg: string, meta: string) => {
    liveChatRef.current.sendMessage(msg, meta);
  };

  // 채팅버튼 클릭시
  const fncBtnChatClick = async (status: boolean) => {
    if (props.userState.id !== "") {
      if (broadInfo.broad_status !== "VOD") liveChatTFRef.current.handleHiddenForm(false);
      setChatOpen((chatOpen) => status);
      // setHiddenMenu((hiddenMenu) => true);
      setTimeout(() => {
        liveChatRef.current.scrollToBottom();
      }, 200);
    }
  };

  // 화면 클릭시 메뉴 숨기기
  const fncBodyClick = (e: any) => {
    const width = window.innerWidth;
    if (width < 200) return;
    if (
      e.target === document.getElementById("tab-screen-content") ||
      e.target === document.getElementsByClassName("thd-chat-list")[0] ||
      e.target === document.getElementsByClassName("live-content-chat")[0] ||
      e.target === document.getElementsByClassName("live-coupon-root")[0] ||
      e.target === document.getElementById("divheartActions")
    ) {
      setHiddenMenu((hiddenMenu) => !hiddenMenu);
      setChatOpen(false);
      setControlOpen(true);
      if (broadInfo.broad_status !== "VOD") liveChatTFRef.current.handleHiddenForm(true);
    }
  };

  const fncResetQuote = () => {
    liveChatRef.current.fncResetQuote();
  };

  // 채널정보 조회
  const getChannelInfo = useCallback(async () => {
    const param: GetChannelinfoParams = {
      pk: `broad_date#${props.broadDate}`,
      broad_seq: props.broadSeq,
      st_add: "1",
    };

    const res = await clientApi.get_channel_info(param);
    if (res.result_code === "200") {
      setBroadInfo(res.result_body);
      const chimeinfo = {
        chime_admin_arn: res.result_body.chime_admin_arn,
        chime_channel_arn: res.result_body.chime_channel_arn,
      };
      setChimeInfo(chimeinfo);
    } else {
      console.error("에러 발생 : " + res.result_body);
    }
  }, [props.broadDate, props.broadSeq]);

  const loadChannelInfoOnly = async () => {
    if (props.broadSeq !== undefined) {
      const param: CBroadParams = {
        broad_seq: props.broadSeq,
      };

      const res = await clientApi.get_channel_info(param);
      res.result_body.pk = `broad_date#${props.broadDate}`;
      setBroadInfo(res.result_body);
    } else {
      console.error("The channel is undefined");
    }
  };

  const loadStatisticInfo = async () => {
    if (props.broadSeq !== undefined) {
      const param: any = {
        broad_seq: props.broadSeq,
      };
      const res = await statisticApi.get_statistic_info(param);
      if (res.code === "200") {
        if (!cUtils.isEmptyObj(res.response.statistic_info)) {
          setLikeCount(res.response.statistic_info.total_likes);
          setTotalViewCount(res.response.statistic_info.total_views);
        }
      }
    } else {
      console.error("loadStatisticInfo - The channel is undefined");
    }
  };

  // 채팅 입력창 포커스
  const chatInpuFocus = () => {
    liveChatTFRef.current.chatInputFocus();
  };

  useEffect(() => {
    getChannelInfo();
    loadStatisticInfo();
  }, []);

  useImperativeHandle(ref, () => ({
    recieveIoTMessage,
  }));

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setBroadInfo({});
      setChimeInfo({});
      setStreamState("");
      setVideoStatus("");
      setMuted(true);
      setBroadWait(false);
      setRemainTime("");
      setVodStartSecFull(0);
      setVodEndSecFull(0);
      setHiddenMenu(false);
      setControlOpen(true);
      setLikeCount(0);
      setTotalViewCount(0);
      setConcurrentView(0);
      setChatOpen(false);
    };
  }, []);

  return (
    <>
      {!cUtils.isEmptyObj(broadInfo) && (
        <Box>
          <Grid className="live-channel-info-mobile-fullscreen" container spacing={1} sx={{ paddingX: 0 }} component="div">
            {/* 라이브 화면 */}
            {/* <Box sx={{ position: "relative", height: { lg: "627px", xs: "740px" }, width: "100%" }}> */}
            <Box sx={{ position: "relative", width: "100%" }}>
              <div className="Live-Layout-inner">
                <div className="Live-Swipe-content">
                  <div className="VideoPlayer-wrapper-admin color-snow">
                    {braodWait && broadInfo.broad_seq !== "" ? (
                      <BroadWait remainTime={remainTime} broad_info={broadInfo} />
                    ) : (
                      props.userState.id !== "" &&
                      broadInfo.broad_status !== "VOD" &&
                      broadInfo.broad_seq !== "" && (
                        <ClientIVSPlayer
                          endLoadInfo={endLoadInfo}
                          broad_seq={broadInfo.broad_seq}
                          broad_status={broadInfo.broad_status}
                          playbackUrl={broadInfo.ivs_playback_url}
                          streamState={streamState}
                          videoStatusCallback={videoStatusCallback}
                          muted={muted}
                          webViewStatus={"web"}
                          rehearsal={false}
                        />
                      )
                    )}
                    {broadInfo.broad_status === "VOD" && broadInfo.broad_seq !== "" ? (
                      vodType === "highlight" ? (
                        <VodIVSPlayerHigh
                          endLoadInfo={endLoadInfo}
                          broad_seq={broadInfo.broad_seq}
                          playbackUrl={appConfig.vod_cloudfront_endpoint + broadInfo.vod_url}
                          vod_start_sec={vodStartSecHigh}
                          vod_end_sec={vodEndSecHigh}
                          videoStatusCallback={videoStatusCallback}
                          videoPositionCallback={videoPositionCallback}
                          hidden_menu={hiddenMenu}
                          muted={muted}
                          controlOpen={controlOpen}
                        />
                      ) : (
                        <VodIVSPlayer
                          endLoadInfo={endLoadInfo}
                          broad_seq={broadInfo.broad_seq}
                          playbackUrl={appConfig.vod_cloudfront_endpoint + broadInfo.vod_url}
                          vod_start_sec={vodStartSecFull}
                          vod_end_sec={vodEndSecFull}
                          videoStatusCallback={videoStatusCallback}
                          videoPositionCallback={videoPositionCallback}
                          hidden_menu={hiddenMenu}
                          muted={muted}
                          controlOpen={controlOpen}
                        />
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className={"Tap-screen-wrapper color-snow"}>
                    <div id="tab-screen-content" className="Tap-screen-content" onClick={fncBodyClick}>
                      <div className="live-header-root">
                        {broadInfo.broad_seq !== "" ? (
                          <LiveInfoHeaderMobile
                            ref={liveHeaderRef}
                            userState={props.userState}
                            broadInfo={broadInfo}
                            broad_seq={broadInfo.broad_seq}
                            broad_title={broadInfo.broad_title}
                            broad_status={broadInfo.broad_status}
                            video_status={videoStatus}
                            host_picture={broadInfo.host_picture}
                            host_name={broadInfo.host_name}
                            postMsg={postMsg}
                            tot_view_count={totalViewCount}
                            tot_like_count={likeCount}
                            tot_concurrent_view={concurrentView}
                            hidden_menu={hiddenMenu}
                            vodType={vodType}
                            haveHighlight={haveHighlight}
                            toggleVodType={toggleVodType}
                            muted={muted}
                            toggleMute={toggleMute}
                            braodWait={braodWait}
                            handleResolution={handleResolution}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="live-content-root">
                        <div className={"live-content-wrapper chat-bg-transparent"}>
                          <div className="live-content-chat">
                            {props.userState.id !== "" && broadInfo.broad_status !== "VOD" && !braodWait && chimeInfo !== undefined && (
                              <LiveInfoChatMobile
                                ref={liveChatRef}
                                broad_seq={broadInfo.broad_seq}
                                channelArn={chimeInfo.chime_channel_arn}
                                adminArn={chimeInfo.chime_admin_arn}
                                chat_open={chatOpen}
                                hidden_menu={hiddenMenu}
                                userState={props.userState}
                                extra_type={broadInfo.extra_type}
                                sendIoTMessage={props.sendIoTMessage}
                                chatInpuFocus={chatInpuFocus}
                              />
                            )}
                          </div>

                          <div className={"live-content-footer"}>
                            {broadInfo.broad_seq !== "" && !braodWait ? (
                              <LiveFooterMobile
                                ref={liveFooterRef}
                                isNotFloat={handleNoFloating}
                                isFloat={handleYesFloating}
                                broad_seq={broadInfo.broad_seq}
                                broad_status={broadInfo.broad_status}
                                user_id={props.userState.id}
                                userState={props.userState}
                                like_click={btnLikeClickCallback}
                                hidden_menu={hiddenMenu}
                                sendMessage={sendMessage}
                                prodList={prodList}
                                postMsg={postMsg}
                                newQnAMsg={newQnAMsg}
                                openChatInput={fncBtnChatClick}
                                videoMode={videoMode}
                                broadInfo={broadInfo}
                                chimeInfo={chimeInfo}
                                broadDate={props.broadDate}
                                sendIoTMessage={props.sendIoTMessage}
                                sendIoTMessageMeta={props.sendIoTMessageMeta}
                                getChannelInfo={getChannelInfo}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                          <LiveInfoChatInput
                            ref={liveChatTFRef}
                            broad_status={broadInfo.broad_status}
                            user_id={props.userState.id}
                            sendMessage={sendMessage}
                            fncResetQuote={fncResetQuote}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
            {/* 라이브 화면 */}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default forwardRef(ChannelInfoMobile);
